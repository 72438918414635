import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import BackgroundImage from 'gatsby-background-image'
import { get } from 'lodash'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import { useStaticQuery, graphql } from 'gatsby'

import SEO from '../../components/atoms/Seo'
import Navbar from '../../components/organisms/Navbar'
import PasswordForm from '../../components/organisms/PasswordForm'
import getSearchFromLocation from '../../utils/params'
import { API_SALES_URL } from '../../config'

const Background = styled(BackgroundImage)`
  position: absolute !important;
  width: 100%;
  height: 100%;
`
const NoContent = styled.div`
  text-align: center;
  margin: 128px 0;
  font-size: 32px;
  font-weight: 600;
  color: ${({ theme }) => get(theme, 'lightOrange')};
`

const EditPassword = ({ location }) => {
  const params = getSearchFromLocation()
  const token = get(params, 'reset_password_token', '')
  const [isVerified, setIsVerified] = useState(false)

  const TITLE = 'Trouver Mon Local Pro - Création mot de passe'
  const DESCRIPTION = 'Créer un nouveau mot de passe pour votre compte utilisateur'

  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "home/tmlp-presentation.webp"}) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }`)

  const verifyToken = useCallback(async () => {
    try {
      const { data } = await axios.post(`${API_SALES_URL}/api/users/verify_token`, { token })
      if (data.valid) {
        setIsVerified(true)
      }
    } catch (e) {
      console.error('unable to verify token ')
    }
  }, [token])

  useEffect(() => {
    verifyToken()
  }, [])

  return (
    <>
      <SEO
        title={TITLE}
        description={DESCRIPTION}>
      </SEO >
      <Navbar location={location} />
      <Background
        {...convertToBgImage(getImage(placeholderImage))}>
        {isVerified && <PasswordForm token={token} />}
        {!isVerified && <NoContent>Lien expiré ou invalide.</NoContent>}
      </Background>
    </>
  )
}

EditPassword.propTypes = {
  location: PropTypes.object.isRequired
}

export default EditPassword
