import React, { useState, useCallback } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { get } from 'lodash'
import { navigate } from 'gatsby'

import FormItem from '../../atoms/FormItem'
import SubmitButton from '../../atoms/SubmitButton'
import { API_SALES_URL } from '../../../config'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  gap: 12px;
  padding: 24px;
  margin: auto;
  min-width: 200px;
  max-width: 400px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  margin-top: 128px;
`
const Title = styled.h2`
  font-weight: 800;
  font-size: 24px;
  color: ${({ theme }) => get(theme, 'blue')};
`
const Submit = styled(SubmitButton)`
  margin-top: 12px;
`

const PasswordForm = ({ token }) => {
  const [isValid, setIsValid] = useState(true)
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const validatePassword = (password, confirmPassword) => {
    if (password.length < 8 || !/\d/.test(password) || !/[a-zA-Z]/.test(password)) {
      setErrorMessage('Le mot de passe doit contenir au moins 8 caractères, dont au moins 1 chiffre et 1 lettre.')
      setIsValid(false)
      return false
    }

    if (password !== confirmPassword) {
      setErrorMessage('Les mots de passe ne correspondent pas.')
      setIsValid(false)
      return false
    }

    setErrorMessage('')
    setIsValid(true)
    return true
  }

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault()
    if (validatePassword(password, confirmPassword)) {
      try {
        await axios.post(`${API_SALES_URL}/api/users/reset_password`, { token, password, confirmation: confirmPassword })
        navigate('/profile/login')
      } catch (e) {
        setIsValid(false)
        setErrorMessage('Impossible de modifier le mot de passe.')
      }
    }
  }, [password, confirmPassword])

  return (
    <Content>
      <Title>Créer votre mot de passe</Title>
      <form onSubmit={handleSubmit}>
        <div>
          <FormItem
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Mot de passe"
          />
        </div>
        <div>
          <FormItem
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirmez le mot de passe"
          />
        </div>
        {!isValid && <p style={{ color: 'red' }}>{errorMessage}</p>}
        <Submit type="submit">Valider</Submit>
      </form>
    </Content>
  )
}

export default PasswordForm
